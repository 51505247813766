import { Container, Row, Col } from "react-bootstrap"
import { faPhone, faAt, faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Footer.module.css';
export default function Footer(){
    return(
        <Container className="text-white p-4">
        <Row>
            <Col>
            <h2 className={`${Styles['hr-line']}`}>Iraq</h2>
                <address >
                    
                    <FontAwesomeIcon icon={faPhone} />&nbsp;
                    +9647501218035<br/>
  
                    <FontAwesomeIcon icon={faAt} />&nbsp;
                    Email Us: <a href="mailto:info@chichotech.com">info@chichotech</a><br/>
                    <FontAwesomeIcon icon={faLocationDot} />&nbsp;
                    Italyan Village 1,Villa 170, Iraq,<br/>
                    Erbil
                </address>

            </Col>
            <Col>
            <h2 className={`${Styles['hr-line']}`}>U.S</h2>
            <address>
                    
                   <FontAwesomeIcon icon={faPhone} />&nbsp;
                    +13123586891<br/>
                    <FontAwesomeIcon icon={faAt} />&nbsp;
                    Email Us: <a href="mailto:info@chichotech.com">info@chichotech</a><br/>
                    <FontAwesomeIcon icon={faLocationDot} />&nbsp;
                    Chicago, IL
                </address>
            </Col>
            <Col></Col>
        </Row>
        </Container>
    )
}