import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/logo.gif';
export default function NavBar(){
    return(
       <>
        <Navbar bg="primary" variant="dark" expand="md">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} width="40"
              height="40"
              className="d-inline-block align-top"
              alt="chichotech"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#experties">Services</Nav.Link>
            <Nav.Link href="#aboutus">About US</Nav.Link>
            <Nav.Link href="#contactus">Contact US</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
       </>
    );
}