import { Row,Col, Container } from "react-bootstrap";
import CustomCard from "../../UI/Card/CustomCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone,faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Styles from './Contact.module.css'
import ContactForm from "./ContactForm";

export default function Contact(){
    
    return(
        <Container>
        <h1 className={`${Styles['hr-line']} text-primary`}>CONTACT US</h1>
        <Row>
            {/*<Col md={4} className="p-2">
            <CustomCard className={`${Styles['custom-card']}`}>
               <ContactForm/>
            </CustomCard>
    </Col>*/}
            <Col className="p-2">
            <CustomCard className={`${Styles['custom-card']}`}>
                     <div className="text-center mb-2 text-primary">
                     <FontAwesomeIcon icon={faEnvelope} size='6x' />
                     <h2 className={`p-3 ${Styles['h1-border__style']}`}>Email US</h2>
                     </div>
                     <div className="text-center p-3">
                     <a className="btn btn-primary btn-lg" href="mailto:info@chichotech.com">Email Us</a><br/>
                    </div>
         </CustomCard>  
            </Col>
            <Col className="p-2">
            <CustomCard className={`${Styles['custom-card']}`}>
                     <div className="text-center mb-2 text-primary">
                     <FontAwesomeIcon icon={faPhone} size='6x'/>
                     <h2 className={`p-3 ${Styles['h1-border__style']}`}>Give us a call</h2>
                     </div>
                     <div className="text-white text-center bg-primary p-2 rounded">
                            <h5><span>Iraq: </span>+964-750-1218035</h5>
                            <h5><span>U.S:</span>+1-312-3586891</h5>
                     </div>
                     
               
         </CustomCard>  
            </Col>
        </Row>
       
        

        </Container>
    )
}