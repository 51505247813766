import About from "../About/About";
import Jumbotron from "../Jumbotron/Jumbotron";
import NavBar from "../NavBarMenu/NavBar";
import Contact from "../Contact/Contact";
import Experties from "../Experties/Experties";
import Styles from "./Layout.module.css"
import Footer from "../Footer/Footer";
export default function Layout(){
    return(
        <>

        <header>
        <NavBar/>
        </header>
        <main>
        <section id="home"  className={Styles['jumbotron-section']}>
        <Jumbotron/>
        </section>
        <section id="experties" className={Styles['experties-section']}>
        <Experties/>
        </section>
        <section id="aboutus" className={`${Styles['about-section']} p-3`}>
        <About />
        </section>
        <section id="contactus" className={`${Styles['contact-section']} p-3`}>
        <Contact/>
        </section>
        </main>
         <footer className={`${Styles['footer-section']}` }>
         <Footer/>
         <div className={`${Styles['footer-section__copyright']} text-center p-3`}>
              <span>&#169; Copyright 2022 R&Z All Rights Reserved.</span>
         </div>
        </footer>
        </>
    )
}