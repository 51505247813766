import { Container, Row, Col, Card} from "react-bootstrap";
import CustomCard from "../../UI/Card/CustomCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styles from './Experties.module.css'
import { faCode, faMobileScreen, faCloud, faDatabase} from '@fortawesome/free-solid-svg-icons'
export default function Experties(){
   
    return(
     <Container>
            <h1 className={`mb-5 text-primary ${Styles['hr-line']}`}>OUR SERVICES</h1>
         <Row className="mb-3" md={3}>
         <Col className="mb-2">
                <CustomCard className={`${Styles['custom-card']}`}>
                    <div className="text-center mb-2 text-primary">
                    <FontAwesomeIcon icon={faCode} size='6x'/>     
                    <h2 className={`p-3 ${Styles['h1-border__style']}`}>Web Application</h2>
                    </div>
                    <p>Present your business to your customer through websites built using cutting-edge technology</p>
                </CustomCard>
                </Col>
                <Col className="mb-2">
                <CustomCard className={`${Styles['custom-card']}`}>
                    <div className="text-center mb-2 text-primary">
                    <FontAwesomeIcon  icon={faMobileScreen} size='6x'/>
                    <h2 className={`p-3 ${Styles['h1-border__style']}`}>Mobile Application</h2>
                    </div>
                    <p>Bring your idea to life and let us develop your mobile app using the cutting-technology</p>
                  
                </CustomCard>
                </Col>
                <Col>
                <CustomCard className={`${Styles['custom-card']}`}>
                     <div className="text-center mb-2 text-primary">
                     <FontAwesomeIcon icon={faCloud} size='6x' />
                     <h2 className={`p-3 ${Styles['h1-border__style']}`}>Cloud Services</h2>
                     </div>
                     <p>Cloud helps your business to operate reliably by enhancing availability and cost reduction</p>
                 </CustomCard>
                 </Col>
               
            </Row>
            <Row className="mb-3">
            
            <Col md={{ span: 4, offset: 4 }}>
            <CustomCard className={`${Styles['custom-card']}`}>
                     <div className="text-center mb-2 text-primary">
                       <FontAwesomeIcon className="text-primary" icon={faDatabase} size='6x' />
                       <h2 className={`p-3 ${Styles['h1-border__style']}`}>Database Managment</h2>
                     </div>
                     <p>Data is the most valuable asset of a business, we will help you to organize and manage your data in a systematic and structural approach.</p>
                     </CustomCard>
                </Col>
        
            </Row>
           
         </Container>
    )
}