import { Col, Container, Row } from 'react-bootstrap';
import Styles from './Jumbotron.module.css'
import MainImage from '../../assets/images/main-picture.png';
export default function Jumbotron(){
    
    return(
            <Container>
                <Row>
                    <Col>
                        <div className='p-5 text-white'>
                           <h1>Leading your business</h1>
                           <h1>to achieve sucess</h1>
                           <a href="#aboutus" className={`btn ${Styles['btn-orange'] } btn-lg`}>About Us</a>
                        </div>
                        
                    </Col>
                    <Col md={6}>
                       <img src={MainImage} className={`img-fluid`} />
                    </Col>
                </Row>
            </Container>
            
    );
}