import { Col, Container, Row } from "react-bootstrap";
import CustomCard from "../../UI/Card/CustomCard";
import Styles from './About.module.css'
import AboutUsImage from '../../assets/images/coding-about-us.jpg';
export default function About(){
    return(
        
        <Container>   
         <h1 className={`${Styles['hr-line']} mb-5 text-primary`}>ABOUT US</h1> 
         <Row className="p-4">
           <Col md={3} className="mb-3">
              <img  src={AboutUsImage} className={`${Styles['img-border']} rounded-pill img-fluid`} width={400}/>
           </Col>
          <Col md={9} >
            <CustomCard className={`${Styles['custom-card']} ${Styles['text-justify']}`}>
               We are a team of software developers and IT consultants with strong experience in developing web, mobile, desktop applications and cloud-based apps. We are passionate and fervent about technology, and we use the cutting-edge technology to deliver the best product to our customer. Our team works in an Agile environment and collaborating with customer to empower their business growth. 
            </CustomCard>
         </Col>
         <Col></Col>
         </Row>
        </Container>
    );
}